<template>
  <div>
    <div
      class="modal fade"
      id="PublicLinkModal"
      tabindex="-1"
      aria-labelledby="PublicLinkModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-centerm-auto" id="PublicLinkModalLabel">
              Public link
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <p class="text-muted" style="font-size: 13px">
              Please select a team you would like use to send the test
            </p>
            <form class="my-2" method="">
              <div class="form-group">
                <label>Team</label>
                <select class="form-select my-2">
                  <option>South HR</option>
                  <option>South HR</option>
                  <option>South HR</option>
                </select>
                <button class="bg-dark text-white form-control">
                  Copy Link
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="FiltersModal"
      tabindex="-1"
      aria-labelledby="FiltersModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-centerm-auto" id="FiltersModalLabel">
              More Filter
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body FiltersModal">
            <div class="Language-Section">
              <h4>Language</h4>
              <form class="row mt-4">
                <div class="col-md-6">
                  <div class="form-check mb-2 LanguageCheckbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="EnglishLang"
                    />
                    <label class="form-check-label" for="EnglishLang">
                      English
                    </label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-check mb-2 LanguageCheckbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="TamilLang"
                    />
                    <label class="form-check-label" for="TamilLang">
                      Tamil
                    </label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-check mb-2 LanguageCheckbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="HindiLang"
                    />
                    <label class="form-check-label" for="HindiLang">
                      Hindi
                    </label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-check mb-2 LanguageCheckbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="MarathiLanf"
                    />
                    <label class="form-check-label" for="MarathiLanf">
                      Marathi
                    </label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-check mb-2 LanguageCheckbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="KannadaLang"
                    />
                    <label class="form-check-label" for="KannadaLang">
                      Kannada
                    </label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-check mb-2 LanguageCheckbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="MandarinLang"
                    />
                    <label class="form-check-label" for="MandarinLang">
                      Mandarin
                    </label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-check mb-2 LanguageCheckbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="GujratiLang"
                    />
                    <label class="form-check-label" for="GujratiLang">
                      Gujrati
                    </label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group mb-2 mt-2">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Add a language"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="Language-Section border-0">
              <h4>Proctering</h4>
              <form class="row mt-4">
                <div class="col-md-6">
                  <div class="form-check mb-2 LanguageCheckbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="EnglishLang"
                    />
                    <label class="form-check-label" for="EnglishLang">
                      Proctering
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn" data-bs-dismiss="modal">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="ViewCriteriaModal"
      tabindex="-1"
      aria-labelledby="ViewCriteriaModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="ViewCriteriaModalLabel"
            >
              Section-wise criteria
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body ViewCriteriaModal">
            <div class="table-responsive">
              <table class="table border">
                <thead>
                  <tr>
                    <th>Section</th>
                    <th>Absolute Benchmark (%)</th>
                    <th>Weightage (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Attention to detail</td>
                    <td>50</td>
                    <td>30</td>
                  </tr>

                  <tr>
                    <td>Listening</td>
                    <td>50</td>
                    <td>30</td>
                  </tr>

                  <tr>
                    <td>Typing</td>
                    <td>30</td>
                    <td>10</td>
                  </tr>

                  <tr>
                    <td>CSE Domain</td>
                    <td>20</td>
                    <td>10</td>
                  </tr>

                  <tr>
                    <td>Written Communication</td>
                    <td>59</td>
                    <td>10</td>
                  </tr>

                  <tr>
                    <td>Customer Service Orientation</td>
                    <td>30</td>
                    <td>10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="ViewCandidatesReportModal"
      tabindex="-1"
      aria-labelledby="ViewCandidatesReportModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="ViewCandidatesReportModalLabel"
            >
              Reports
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <p class="text-muted" style="font-size: 13px">Allan Wong</p>
            <form class="my-2" method="">
              <div class="form-group">
                <label>Time</label>
                <input
                  type="text"
                  name="daterange"
                  value=""
                  placeholder="Select dates "
                  class="form-control"
                />
              </div>
            </form>

            <div class="mt-4 modalStatus-section">
              <h6>Status</h6>
              <div class="btn btn-success">Pass</div>
            </div>

            <div class="row download-option-section">
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card px-3 py-3">
                  <ul class="navbar-nav">
                    <li>
                      Overall<button class="btn">
                        <i class="fas fa-download mx-2"></i>
                      </button>
                    </li>
                    <li>
                      Behavioral<button class="btn">
                        <i class="fas fa-download mx-2"></i>
                      </button>
                    </li>
                    <li>
                      Language<button class="btn">
                        <i class="fas fa-download mx-2"></i>
                      </button>
                    </li>
                    <li>
                      Proctor<button class="btn">
                        <i class="fas fa-download mx-2"></i>
                      </button>
                    </li>
                    <li>
                      Answer sheet<button class="btn">
                        <i class="fas fa-download mx-2"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="ViewCandidatesDownloadModal"
      tabindex="-1"
      aria-labelledby="ViewCandidatesDownloadModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="ViewCandidatesDownloadModalLabel"
            >
              Consolidated Sheet
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <form class="" method="post">
              <div class="form-group mb-2">
                <label>Team</label>
                <select class="form-select my-2">
                  <option>South Vodafone</option>
                  <option>Techmahindra</option>
                  <option>Startech</option>
                  <option>Cogent</option>
                </select>
              </div>

              <div class="form-group datepicker-section">
                <label>Date</label>
                <input
                  type="text"
                  name="daterange"
                  value=""
                  placeholder="Select dates "
                  class="form-control"
                />
              </div>

              <button class="bg-dark text-white form-control mt-3">
                Download .xlsx
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="ViewCandidatesAddDateModal"
      tabindex="-1"
      aria-labelledby="ViewCandidatesAddDateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="ViewCandidatesAddDateModalLabel"
            >
              Add Date
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <form class="" method="post">
              <div class="form-group datepicker-section">
                <input
                  type="text"
                  name="daterange"
                  value=""
                  placeholder="Select dates "
                  class="form-control"
                />
              </div>

              <button class="bg-dark text-white form-control mt-3">Done</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="InviteCandidatesConfirm"
      tabindex="-1"
      aria-labelledby="InviteCandidatesConfirmLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="InviteCandidatesConfirmLabel"
            >
              Reinvite or Cancle invite
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <p class="text-muted">
              You have request an invitation for 27 candidates for
              <strong class="text-dark">Cso Test.</strong>
            </p>

            <p class="my-3" style="font-weight: 500">
              There are 7 with duplicated invitation
            </p>
            <div class="col-md-12 stretch-card">
              <div class="card p-0">
                <div class="card-body">
                  <button
                    class="btn"
                    data-bs-dismiss="modal"
                    aria-label="btn-close"
                  >
                    <i class="fas fa-check-double"></i> Confirm to reinvite all
                    candidates
                  </button>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="btn showDetailButton form-control"
              data-bs-toggle="modal"
              data-bs-target="#InviteCandidatesduplicated"
            >
              Show Details
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="InviteCandidatesduplicated"
      tabindex="-1"
      aria-labelledby="InviteCandidatesduplicatedLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="InviteCandidatesduplicatedLabel"
            >
              Duplicated Invitation
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body Invite-duplicated-section">
            <p>Search and select the emails you would like to resend</p>
            <form class="duplicated-mailId">
              <div class="col-md-12">
                <div class="form-group mb-2 mt-2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search Email"
                  />
                </div>
              </div>

              <div class="mailIds">
                <div class="form-check mb-2 mailId-checkbox">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="EnglishLang"
                  />
                  <label class="form-check-label" for="EnglishLang">
                    select all
                  </label>
                </div>

                <div class="form-check mb-2 mailId-checkbox">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="TamilLang"
                  />
                  <label class="form-check-label" for="TamilLang">
                    edouard@gmail.com
                  </label>
                </div>

                <div class="form-check mb-2 mailId-checkbox">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="HindiLang"
                  />
                  <label class="form-check-label" for="HindiLang">
                    prakash@gmail.com
                  </label>
                </div>

                <div class="form-check mb-2 mailId-checkbox">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="MarathiLanf"
                  />
                  <label class="form-check-label" for="MarathiLanf">
                    sanya@gmail.com
                  </label>
                </div>

                <div class="form-check mb-2 mailId-checkbox">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="MarathiLanf"
                  />
                  <label class="form-check-label" for="MarathiLanf">
                    ann@gmail.com
                  </label>
                </div>

                <div class="form-check mb-2 mailId-checkbox">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="MarathiLanf"
                  />
                  <label class="form-check-label" for="MarathiLanf">
                    bob@gmail.com
                  </label>
                </div>
              </div>

              <div class="d-flex">
                <button
                  class="bg-white text-dark btn-outline-dark form-control mt-3 mx-2"
                >
                  Cancel
                </button>
                <button class="bg-dark text-white form-control mt-3 mx-2">
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="IncreaseTokenModal"
      tabindex="-1"
      aria-labelledby="IncreaseTokenModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="IncreaseTokenModalLabel"
            >
              Increase test token
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body py-4">
            <p class="text-center m-auto" style="width: 70%">
              Increse test token for <strong>Vodafone</strong>
            </p>
            <form class="row" method="">
              <div class="tokenPrice">
                <div
                  class="value-button"
                  id="decrease"
                  onclick="decreaseValue()"
                  value="Decrease Value"
                >
                  -
                </div>
                <input type="number" id="number" value="100" />
                <div
                  class="value-button"
                  id="increase"
                  onclick="increaseValue()"
                  value="Increase Value"
                >
                  +
                </div>
              </div>
              <p class="text-center">Balance: 270</p>
              <div class="d-flex mt-2">
                <button class="bg-dark text-white form-control mt-3 mx-2">
                  Request
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="AllocateTestTokenModal"
      tabindex="-1"
      aria-labelledby="AllocateTestTokenModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="AllocateTestTokenModalLabel"
            >
              Allocate Test Token
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body py-4">
            <p class="text-center m-auto" style="width: 90%">
              Allocate test token for <strong>Test 2021</strong> for
              <strong>South Vodafone</strong>
            </p>
            <form class="row" method="">
              <div class="tokenPrice">
                <div
                  class="value-button"
                  id="decrease"
                  onclick="decreaseValue()"
                  value="Decrease Value"
                >
                  -
                </div>
                <input type="number" id="number" value="100" />
                <div
                  class="value-button"
                  id="increase"
                  onclick="increaseValue()"
                  value="Increase Value"
                >
                  +
                </div>
              </div>
              <p class="text-center">Balance: 100</p>
              <div class="d-flex mt-2">
                <button class="bg-dark text-white form-control mt-3 mx-2">
                  Increase Test
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="TransferTestTokenTokenModal"
      tabindex="-1"
      aria-labelledby="TransferTestTokenTokenModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="TransferTestTokenTokenModalLabel"
            >
              Transfer Test Token
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body py-4">
            <form class="row" method="">
              <div class="form-group">
                <div class="mb-2">
                  <label>From Test</label>
                  <label style="float: right"
                    ><strong>Balance: 30</strong></label
                  >
                </div>
                <select class="form-select">
                  <option>My test 2022</option>
                </select>
              </div>
              <div class="form-group mt-3">
                <input
                  type="text"
                  name=""
                  placeholder="100"
                  class="form-control"
                />
              </div>
              <div class="TransferIcon">
                <i class="fas fa-exchange-alt"></i>
              </div>
              <div class="form-group">
                <div class="mb-2">
                  <label>To Test</label>
                  <label style="float: right"
                    ><strong>Balance: 10</strong></label
                  >
                </div>
                <select class="form-select">
                  <option>My test 2022</option>
                </select>
              </div>
              <div class="d-flex mt-2">
                <button class="bg-dark text-white form-control mt-3 mx-2">
                  Transfer Test
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="ManagementPratnerIncreaseTokenModal"
      tabindex="-1"
      aria-labelledby="ManagementPratnerIncreaseTokenModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="ManagementPratnerIncreaseTokenModalLabel"
            >
              Increase Test Token
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body py-4">
            <p class="text-center m-auto" style="width: 90%">
              Request to increse test token for <strong> Vodafone</strong>
            </p>

            <form class="row" method="">
              <div class="tokenPrice">
                <div
                  class="value-button"
                  id="decrease"
                  onclick="decreaseValue()"
                  value="Decrease Value"
                >
                  -
                </div>
                <input type="number" id="number" value="100" />
                <div
                  class="value-button"
                  id="increase"
                  onclick="increaseValue()"
                  value="Increase Value"
                >
                  +
                </div>
              </div>
              <p class="text-center">Balance: 160</p>
              <div class="d-flex mt-2">
                <button class="bg-dark text-white form-control mt-3 mx-2">
                  Request
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="AssignPartnersTeamsModal"
      tabindex="-1"
      aria-labelledby="AssignPartnersTeamsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="AssignPartnersTeamsModalLabel"
            >
              Transfer Test Token
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body py-4">
            <form class="row" method="">
              <div class="form-group">
                <div class="mb-2">
                  <label>From Test</label>
                  <label style="float: right"
                    ><strong>Balance: 30</strong></label
                  >
                </div>
                <select class="form-select">
                  <option>My test 2022</option>
                </select>
              </div>
              <div class="form-group mt-3">
                <input
                  type="text"
                  name=""
                  placeholder="100"
                  class="form-control"
                />
              </div>
              <div class="TransferIcon">
                <i class="fas fa-exchange-alt"></i>
              </div>
              <div class="form-group">
                <div class="mb-2">
                  <label>To Test</label>
                  <label style="float: right"
                    ><strong>Balance: 10</strong></label
                  >
                </div>
                <select class="form-select">
                  <option>My test 2022</option>
                </select>
              </div>
              <div class="d-flex mt-2">
                <button class="bg-dark text-white form-control mt-3 mx-2">
                  Transfer Test
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="AddTeamMembersModal"
      tabindex="-1"
      aria-labelledby="AddTeamMembersModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="AddTeamMembersModalLabel"
            >
              Add Team Members
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <form class="row" method="">
              <div class="form-group mb-2 mt-3">
                <label class="pb-1" style="color: #646464">User Name </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="User Name"
                  required
                  value="Fei"
                />
              </div>

              <div class="d-flex mt-3 border-bottom pb-3">
                <div class="PartnerTeamMembersProfile">FT</div>
                <div class="text-muted">
                  <b>Fei Tahina</b>
                  <p style="font-size: 12px">fei@vodafone.com</p>
                  <p>Pending invitation <i class="fas fa-envelope px-2"></i></p>
                </div>
              </div>

              <button class="d-flex my-3 btn align-items-center">
                <div
                  class="PartnerTeamMembersProfile bg-dark"
                  style="letter-spacing: 0"
                >
                  <i class="fas fa-plus"></i>
                </div>
                <div>
                  <strong>NEW USER</strong>
                </div>
              </button>

              <div class="d-flex mt-2">
                <button
                  class="bg-white text-dark btn-outline-dark form-control mt-3 mx-2"
                  data-bs-dismiss="modal"
                  aria-label="btn-close"
                >
                  Cancel
                </button>
                <button class="bg-dark text-white form-control mt-3 mx-2">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Dashboard-Footer",
};
</script>
<style></style>
