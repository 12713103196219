<template>
  <div :class="['main-wrapper', isSidebarOpen ? 'sidebar-open' : '']">
    <Sidebar v-if="showSidebar" @toggle-sidebar="isSidebarOpen = !isSidebarOpen" :is-sidebar-open="isSidebarOpen"
      @hide-sidebar="isSidebarOpen = false" />
    <div class="page-wrapper">
      <BillingAlert />
      <!-- 
      <PlanExpiry /> -->
      <Header @toggle-sidebar="isSidebarOpen = !isSidebarOpen" />
      <router-view />
    </div>
    <DashboardFooter />
  </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";
import Header from "./Header.vue";
import BillingAlert from "./BillingAlert.vue";
// import PlanExpiry from "./PlanExpiry.vue";
import DashboardFooter from "./DashboardFooter.vue";
import {getAuthUserData} from "../utils/auth"
import { mapActions } from "vuex";
export default {
  components: {
    Sidebar,
    Header,
    BillingAlert,
    // PlanExpiry,
    DashboardFooter,
    
},
  data() {
    return {
      isSidebarOpen: false,
      permissions: false,
      showSidebar:false
    };
  },
  mounted(){
    this.showSi();
  },
  watch: {
    permissions(newP) {
      this.showSidebar = newP;
    }
  },
 
  methods: {
    ...mapActions([
      "GetloggedinPermissions"
    ]),
    async showSi() {
    const userAuth = getAuthUserData();
    var permission = [];
    if (localStorage.getItem("Userpermission")) {
      permission = localStorage.getItem("Userpermission")
    } else {
      var response = await this.GetloggedinPermissions(userAuth.userId)
      permission = response.data;
    }
    if (permission) {
      this.permissions = true;
    }
    else {
      location.reload();
      this.permissions = false
    }
  },

  }

};
</script>
<style lang="css">
@import url("/dashboard-assets/css/style.min.css");
</style>
