<template> 
  <div v-if="showBillingDays">
    <div class="billingDays" style="margin-top: 60px !important; min-width: 90%;">
      <div class="billingContent">
        <span
          style="margin-left: 20px !important; margin-top: 5px !important; font-weight: 600; color: #454545; font-size: 14px;">
          <i class="fa-solid fa-triangle-exclamation fa-lg" style="color: #FFAD28;"></i>
          <span class="mx-3" v-if="this.Remainingdays <= 0">
            Your plan has expired 
          </span>
          <span v-else>
            Your plan will expire in {{this.Remainingdays}} Days
          </span>


        </span>
        <button style="background-color: #FFAD28 !important; border-color:#FFAD28 !important; color:rgb(54, 54, 54) ; font-size: small; border-radius: 3px; height: 50%; ">
        <router-link :to="{ name: 'BillingPlan' }" style="color: black;" >
            Upgrade Now
      </router-link>
    </button>
        <!-- <button 
          style="background-color: #FFAD28 !important; border-color:#FFAD28 !important; color:rgb(54, 54, 54) ; font-size: small; border-radius: 3px; ">
          Upgrade Now
        </button> -->
      </div>
      <div @click="closeBillingDays()" style="margin-right: 10%;" v-if="this.Remainingdays>0">
        <i class="fa-solid fa-xmark ms-auto" style="color: #454545; cursor: pointer;"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { calculateRemainingDays } from '../platformFeatures/dateUtils';
import moment from "moment";
export default {
  name: "BillingAlert",
  components: {
  },
  data() {
    return {
      
      showBillingDays: false,
      Remainingdays: null,
      billing:JSON.parse(localStorage.getItem('billingData')),

    };
  },

  created() {

    if(this.user.isEnterprise==false){
      this.Remainingdays = calculateRemainingDays(this.billing.object.data.subscription.expiryDate);
      if(this.Remainingdays<=7){
        this.showBillingDays = true;
      }
      else if(this.Remainingdays >=0){
        this.showBillingDays = false;
      document.documentElement.style.setProperty('--page-content-margin-top-open', 'var(--page-content-margin-top-closed)'); 
      }
    }else{
    document.documentElement.style.setProperty('--page-content-margin-top-open', 'var(--page-content-margin-top-closed)'); 

    }


    //  if (this.Remainingdays >=0) {
    //   this.showBillingDays = false;
    //   document.documentElement.style.setProperty('--page-content-margin-top-open', 'var(--page-content-margin-top-closed)');
    //  }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapMutations(["setLoading"]),

    dateConvert: (value) => {
      return moment(String(value)).format("DD/MM/YYYY");
    },
  
   closeBillingDays() {
      this.showBillingDays = false;
      document.documentElement.style.setProperty('--page-content-margin-top-open', 'var(--page-content-margin-top-closed)');
    },
   
  },
};
</script>
<style scoped>
.billingDays {
  background: #feebb4;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 0;
  position: fixed;
  right: 0;
  left: 240px;
  z-index: 978;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, left 0.1s ease;
  height: 40px !important;
  
}
/* .billingDays span {
    width: 70%; 
  } */
@media (max-width: 991px) {
  .billingDays {
    left: 0; 
    width: 100%; 
    height: 40px !important; 
    /* flex-direction: row;  */
    /* justify-content: center;  */
    /* align-items: center; */
  }
 
  .billingDays .upgrade {
    /* margin-left: 20%; */
        /* justify-content: center;  */
    width: 120px !important; 
    border-radius: 25px !important;
  }

 

  .billingDays i.fa-xmark {
  color: #454545;
  margin-right: 10px;
  cursor: pointer;
}
}
</style>